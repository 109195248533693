import './App.css';
import { useEffect, useState } from 'react';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import Main from './components/Main.jsx';

function App() {
    const [hideHeader, setHideHeader] = useState(false)
    const [empty, setEmpty] = useState(true)

    const origin = process.env.REACT_APP_HOST;

    //Excute js
    const executeAndRemoveScript = (script) => {
        if (script) {
            if (script.src) {
                let newScript = document.createElement('script');
                newScript.src = script.src;
                newScript.onload = function () {
                    script.parentNode.removeChild(script);
                };
                document.body.appendChild(newScript);
            } else {
                try {
                    let newScript = document.createElement('script');
                    newScript.textContent = script.textContent;
                    document.body.appendChild(newScript);
                    script.parentNode.removeChild(script);
                } catch { }
            }
        }
    }

    const appendDivToGroup = (group, div) => {
        const groupEl = document.getElementById(group + '-group')
        if (groupEl) {
            groupEl.appendChild(div)
        }
    }

    //Get all actions
    useEffect(() => {
        const handler = async (e) => {
            if (e.origin === origin) {
                const getAppendElement = (html) => {
                    const div = document.createElement('div');
                    div.innerHTML = html;
                    const appendDiv = div.children[0];

                    //Chặn thẻ a
                    const links = appendDiv.querySelectorAll('a:not(.smi-hook-lightbox)')
                    links.forEach(link => {
                        const role = link.getAttribute('role');
                        if (role !== 'button') {
                            link.addEventListener('click', (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            })
                        }
                    })

                    //Chặn submit form
                    const forms = appendDiv.querySelectorAll('.smi-form, .smi-section form');
                    forms.forEach(form => {
                        form.addEventListener('click', (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        })
                    })

                    //Pagination click
                    appendDiv.querySelectorAll('.smi-component-sticky-page-dot').forEach(el => {
                        const btnNext = el.querySelector('[data-control="textNextSlide"]');
                        const btnPrev = el.querySelector('[data-control="textPrevSlide"]');
                        if (btnNext && btnPrev) {
                            let swiperEl = el.closest('.smi-section__inner').querySelector(".smi-swiper-nested");
                            if (!swiperEl) {
                                swiperEl = el.closest('.smi-section__inner').querySelector('.swiper')
                            }
                            btnNext.addEventListener('click', () => {
                                swiperEl.swiper.slideNext()
                            })
                            btnPrev.addEventListener('click', () => {
                                swiperEl.swiper.slidePrev()
                            })
                        }
                    });

                    let scripts = appendDiv.getElementsByTagName('script');
                    let scriptCount = scripts.length;

                    setTimeout(() => {
                        for (let i = 0; i < scriptCount; i++) {
                            try {
                                executeAndRemoveScript(scripts[i]);
                            } catch (e) { }

                        }
                    }, 100);

                    return appendDiv;
                }

                /**
                 * Theme builder
                 * Khi thay đổi template thì kiểm tra xem
                 * Nếu là template password thì ẩn header/footer group
                 */
                if (e.data.type.includes('change-template')) {
                    const template = e.data.handle;

                    const headerGroupEl = document.getElementById('header-group');
                    const footerGroupEl = document.getElementById('footer-group');
                    const globalGroupEl = document.getElementById('global-group');

                    if (template === 'password') {
                        if (headerGroupEl) { headerGroupEl.classList.add('d-none'); }
                        if (footerGroupEl) { footerGroupEl.classList.add('d-none'); }
                        if (globalGroupEl) { globalGroupEl.classList.add('d-none'); }
                    } else {
                        if (headerGroupEl) { headerGroupEl.classList.remove('d-none'); }
                        if (footerGroupEl) { footerGroupEl.classList.remove('d-none'); }
                        if (globalGroupEl) { globalGroupEl.classList.remove('d-none'); }
                    }
                }

                /**
                 * All
                 * Remove loading khi iframe loaded
                 */
                if (e.data.type.includes('remove-loading')) {
                    const wrapper = document.querySelector('._wrapper')
                    wrapper && wrapper.classList.remove('loading')
                }

                /**
                 * All
                 * Move element when dragging
                 */
                if (e.data.type.includes('move')) {
                    let temp = e.data.data
                    let appendEl = document.getElementById(temp.belowId)
                    let currentEl = document.getElementById(temp.currentId)

                    if (currentEl) {
                        currentEl.classList.add('move')
                    }

                    appendEl.parentNode.insertBefore(currentEl, appendEl.nextSibling);

                    if (appendEl) {
                        currentEl.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                        });
                    }
                }

                /**
                 * All
                 * Khi chọn item section bên sidebar thì preview bên này active
                 */
                if (e.data.type.includes('select')) {
                    const els = document.querySelectorAll('.section.active, .section.move')
                    els.forEach(it => {
                        it.classList.remove('active')
                        it.classList.remove('move')
                    })
                    const el = document.getElementById(e.data.data.currentId)
                    if (el) {
                        el.classList.add('active')
                        setTimeout(() => {
                            el.scrollIntoView({
                                behavior: "smooth"
                            });
                        }, 10)
                    }
                }

                /**
                 * All
                 * Move end
                 */
                if (e.data.type.includes('moveEnd')) {
                    const els = document.querySelector('.section.move')
                    if (els) {
                        els.classList.remove('move')

                        setTimeout(() => {
                            els.scrollIntoView({
                                behavior: "smooth",
                                // block: "center",
                                // inline: "center",
                            });
                        }, 250)
                    }
                }

                //Move start
                if (e.data.type.includes('moveStart')) {
                    const elsActive = document.querySelector('.section.active')
                    if (elsActive) {
                        elsActive.classList.remove('active')
                    }

                    const el = document.getElementById(e.data.data.currentId)
                    if (el) {
                        el.classList.add('move')
                        el.classList.add('active')
                    }
                }

                //Delete
                if (e.data.type.includes('delete')) {
                    let temp = e.data.data
                    let currentEl = document.getElementById(temp.currentId)
                    if (currentEl) {
                        currentEl.remove()
                    }

                    const wrapper = document.querySelector('.builder-preview')
                    setEmpty((wrapper.childNodes.length <= 1))
                }

                //Delete all
                if (e.data.type.includes('delete-all')) {
                    const group = e.data?.group
                    if (group === 'template') {
                        const sections = document.querySelectorAll('.builder-preview #template-group .section')
                        sections.forEach(it => {
                            it.remove();
                        })
                    } else {
                        const sections = document.querySelectorAll('.builder-preview .section')
                        sections.forEach(it => {
                            it.remove();
                        })
                        setEmpty(true)
                    }
                }

                //Hide
                if (e.data.type.includes('hide')) {
                    let temp = e.data.data
                    let currentEl = document.getElementById(temp.currentId)
                    if (currentEl) {
                        currentEl.classList.add('hidden')
                    }
                }

                //Show
                if (e.data.type.includes('show')) {
                    let temp = e.data.data
                    let currentEl = document.getElementById(temp.currentId)
                    if (currentEl) {
                        currentEl.classList.remove('hidden')
                    }
                }

                if (e.data.type.includes('magic-all')) {
                    let temp = e.data.data
                    let scrollDiv = null;
                    const { data } = temp

                    try {
                        const sections = Object.values(data);
                        if (sections.length) {
                            for (let i = 0; i < sections.length; i++) {
                                const section = sections[i];
                                const { currentId, html } = section;

                                const div = getAppendElement(html);
                                if (i === 0) {
                                    scrollDiv = div;
                                }

                                const currentEl = document.getElementById(currentId);
                                currentEl.insertAdjacentElement('afterend', div);
                                currentEl.remove();
                            }
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    window.parent.postMessage({
                        type: ['magic-all-end']
                    }, '*');

                    clickToPreview()

                    if (scrollDiv) {
                        scrollDiv.scrollIntoView({
                            behavior: "smooth",
                        });
                    }
                }

                //Add
                if (e.data.type.includes('add')) {
                    //console.time('renderHTML');
                    let temp = e.data.data

                    try {
                        setEmpty(false)
                        const { html, currentSection, group } = temp
                        const parent = document.querySelector('.builder-preview')

                        //Data    
                        const div = getAppendElement(html);
                        if (!!currentSection) {
                            const currentEl = document.getElementById(currentSection.randomId);
                            currentEl.insertAdjacentElement('afterend', div);
                            currentEl.remove()
                        } else {
                            if (!!group) {
                                appendDivToGroup(group, div)
                            } else {
                                parent.appendChild(div);
                            }
                        }

                        setTimeout(() => {
                            div.scrollIntoView({
                                behavior: "smooth",
                            });
                        }, 5)
                    } catch (error) {
                        console.error("Error:", error);
                    }
                    //console.timeEnd('renderHTML');
                    clickToPreview()
                }

                if (e.data.type.includes('add-many')) {
                    setEmpty(false);

                    const { html, groups, isDelete } = e.data.data

                    let scrollDiv = null;

                    let parent = null
                    if (!!groups) {
                        parent = document.querySelector('.builder-preview #template-group');
                        setHideHeader(true);
                        setEmpty(false);
                    } else {
                        parent = document.querySelector('.builder-preview')
                    }

                    if (!!isDelete) {
                        if (!!groups) {
                            const sections = document.querySelectorAll('.builder-preview #template-group .section')
                            sections.forEach(it => {
                                it.remove();
                            })
                        } else {
                            const sections = document.querySelectorAll('.builder-preview .section')
                            sections.forEach(it => {
                                it.remove();
                            })
                        }
                    }

                    try {
                        if (!!groups) {
                            if (Object.keys(html).length) {
                                for (let i = 0; i < groups.length; i++) {
                                    const groupName = groups[i];
                                    const _parent = document.querySelector(`.builder-preview #${groupName}-group`);
                                    if (_parent) {
                                        for (let j = 0; j < Object.keys(html[groupName]).length; j++) {
                                            const _html = Object.values(html[groupName])[j];
                                            const div = getAppendElement(_html);
                                            if (i === 0 && j === 0) {
                                                scrollDiv = div;
                                            }
                                            _parent.appendChild(div);
                                        }
                                    }
                                }
                            }
                        } else {
                            for (let i = 0; i < Object.keys(html).length; i++) {
                                const section = Object.values(html)[i];
                                const div = getAppendElement(section);
                                if (i === 0) {
                                    scrollDiv = div;
                                }
                                parent.appendChild(div);
                            }
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    setTimeout(() => {
                        if (!!isDelete && scrollDiv) {
                            scrollDiv.scrollIntoView({
                                behavior: "smooth",
                            });
                        }
                        clickToPreview()
                    }, 10)
                }

                //Change style
                if (e.data.type.includes('change-style')) {
                    let temp = e.data.data

                    const styleOld = document.querySelector('#preview-style')
                    if (styleOld) {
                        styleOld.remove();
                    }

                    const head = document.head || document.getElementsByTagName('head')[0]
                    const styleTag = document.createElement('style');
                    styleTag.id = 'preview-style'
                    head.appendChild(styleTag);
                    styleTag.type = 'text/css';
                    if (styleTag.styleSheet) {
                        // This is required for IE8 and below.
                        styleTag.styleSheet.cssText = temp.style;
                    } else {
                        let _temp = temp.style;
                        styleTag.appendChild(document.createTextNode(_temp));
                    }
                }

                //Change font
                if (e.data.type.includes('change-font')) {
                    let temp = e.data.data

                    const { style, remove } = temp;

                    const styleOld = document.querySelector('#preview-font')
                    if (styleOld) {
                        styleOld.remove();
                    }

                    if (!remove && style !== '') {
                        const head = document.head || document.getElementsByTagName('head')[0]
                        const styleTag = document.createElement('style');
                        styleTag.id = 'preview-font'
                        head.appendChild(styleTag);
                        styleTag.type = 'text/css';
                        if (styleTag.styleSheet) {
                            styleTag.styleSheet.cssText = style;
                        } else {
                            styleTag.appendChild(document.createTextNode(style));
                        }
                    } else {
                        console.log('remove-font');
                    }
                }

                //Toggle header/footer
                if (e.data.type.includes('hide-header')) {
                    let temp = e.data.data
                    setHideHeader(temp)
                }
            }
        }

        window.addEventListener('message', handler)
        return () => window.removeEventListener('message', handler)
    }, [origin])

    //Click to preview to active on sidebar items
    const clickToPreview = () => {
        const elements = document.querySelectorAll('.section')
        if (elements.length) {
            elements.forEach(element => {
                element.addEventListener('click', () => {
                    const activeElement = document.querySelector('.section.active, .section.move')
                    if (activeElement) {
                        activeElement.classList.remove('active')
                        activeElement.classList.remove('move')
                    }
                    element.classList.add('active')
                    const id = element.getAttribute('id')
                    window.parent.postMessage({
                        type: ['select'],
                        data: {
                            currentId: id
                        }
                    }, '*');
                })

                element.addEventListener('mouseleave', () => {
                    if (element.classList.contains('active')) {
                        element.classList.remove('active')
                        window.parent.postMessage({
                            type: ['select'],
                            data: {
                                currentId: ''
                            }
                        }, '*');
                    }
                })
            })
        }
    }

    useEffect(() => {
        clickToPreview()
    }, [])

    return (
        <div className="_wrapper">
            <Header status={hideHeader} />
            <Main status={empty} />
            <Footer status={hideHeader} />
        </div>
    )
}

export default App;
