function Loading() {
    return (
        <div className='_loading'>
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                <path d="M35.961 30.9538L30.9932 33.8197V16.7473L25.9633 19.5516L21.0886 16.7473L33.446 9.56703L35.961 10.9846V30.9538Z" fill="#3E51FE" />
                <path d="M1 10.9846V30.9538L6.12306 33.8197V16.7473L21.0886 25.2835V33.8197L18.4494 35.3297L15.9655 33.8197V25.2835L10.9667 22.4792V36.7473L18.4494 41L25.9633 36.7473V22.4792L15.9655 16.7473V8.21109L18.4184 6.76271L21.0886 8.21109V13.7889L25.9633 10.9846V5.28351L18.4184 1L10.9667 5.28351V13.7889L3.51496 9.56703L1 10.9846Z" fill="#3E51FE" />
            </svg>
        </div>
    )
}

export default Loading
