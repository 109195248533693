function Footer({ status }) {
    return !status ? <>
        <div className='shopify-section smi-section fake-element --footer'>
            <div className='fake-element__inner'>
                <h2 className='_logo'>
                    THEME FOOTER
                </h2>
                <div className='_column'>
                    <div className='_fake-1' style={{ marginBottom: "20px" }}></div>
                    <div className='_fake-2' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2 --style-1' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2 --style-2'></div>
                </div>
                <div className='_column'>
                    <div className='_fake-1' style={{ marginBottom: "20px" }}></div>
                    <div className='_fake-2' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2 --style-1' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2 --style-2'></div>
                </div>
                <div className='_column'>
                    <div className='_fake-1' style={{ marginBottom: "20px" }}></div>
                    <div className='_fake-2' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2 --style-1' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2' style={{ marginBottom: "16px" }}></div>
                    <div className='_fake-2 --style-2'></div>
                </div>
            </div>
        </div>
    </> : null
}

export default Footer
